<template>
 <NavbarSection  titleClass="ml-4 " :section="$t('Inicio')" />
  <div class="home">
    <!-- modal de servicios izquierdo -->
    <div class="modal-container-point-uno">
      <div class="modal-point-uno modal-close-point-uno">
          <div class="close-point-uno">
              <p class="equis">X</p>
          </div>
          <div class="container-items-point">
              <div class="container-titulo">
                  <img :src="`${$apiDevuelvoya}images/shared/truckder.png`" alt="">
                  <span>{{ $t('Logística inversa') }}  </span>
              </div>
              <div class="sub-box-items-point">
                  <div class="box-items-point">
                      <img :src="`${$apiDevuelvoya}images/shared/inven.png`" alt="">
                      <span class="escritura"> {{ $t('Gestión de proveedores') }} </span>
                  </div>
              </div>
              <div class="sub-box-items-point">
                  <div class="box-items-point">
                      <img :src="`${$apiDevuelvoya}images/shared/inventario.png`" alt="">
                      <span class="escritura">{{ $t('Administración de Stocks') }} </span>
                  </div>
              </div>
              <div class="sub-box-items-point">
                  <div class="box-items-point">

                      <img :src="`${$apiDevuelvoya}images/shared/fabrica.png`" alt="">
                      <span class="escritura">{{ $t('Gestión de Stocks Outlets Fulfillment') }} </span>
                  </div>
              </div>
              <div class="sub-box-items-point">
                  <div class="box-items-point">
                      <img :src="`${$apiDevuelvoya}images/shared/kits.png`" alt="">
                      <span class="escritura">{{ $t('Armado de kits de entrega') }} </span>
                  </div>
              </div>
              <div class="sub-box-items-point">
                  <div class="box-items-point">
                      <img :src="`${$apiDevuelvoya}images/shared/almacen.png`" alt="">
                      <span class="escritura">{{ $t('Unificación y packaging') }} </span>
                  </div>
              </div>
              <div class="sub-box-items-point">
                  <div class="box-items-point">
                      <img :src="`${$apiDevuelvoya}images/shared/mercado.png`" alt="">
                      <span class="escritura">{{ $t('Gestión de Clientes y Puntos de Venta') }} </span>
                  </div>
              </div>
              <div class="sub-box-items-point">
                  <div class="box-items-point">
                      <img :src="`${$apiDevuelvoya}images/shared/megafono.png`" alt="">
                      <span class="escritura">{{ $t('Material Publicitarios y POP') }} </span>
                  </div>
              </div>
              <div class="sub-box-items-point">
                  <div class="box-items-point">
                      <img :src="`${$apiDevuelvoya}images/shared/entrega-a-domicilio.png`" alt="">
                      <span class="escritura">{{ $t('Gestión de entrega domiciliarias') }} </span>
                  </div>
              </div>
              <div class="sub-box-items-point">
                  <div class="box-items-point">
                      <img :src="`${$apiDevuelvoya}images/shared/soporte-tecnico.png`" alt="">
                      <span class="escritura">{{ $t('Gestión de Customer Services') }} </span>
                  </div>
              </div>
          </div>
      </div>
    </div>

   <!-- modal de servicios derecho -->
   <div class="modal-container-point-dos">
    <div class="modal-point-dos modal-close-point-dos">
        <div class="close-point-dos">
            <p class="equis">X</p>
        </div>
        <div class="container-items-point">
            <div class="container-titulo">
                <span>{{ $t('Logística directa') }}</span>
                <img :src="`${$apiDevuelvoya}images/shared/truckizq.png`" alt="">
            </div>
            <div class="sub-box-items-point">
                <div class="box-items-point">
                    <img :src="`${$apiDevuelvoya}images/shared/radar.png`" alt="">
                    <span class="escritura">{{ $t('Geoposicionamiento') }}</span>
                </div>
            </div>
            <div class="sub-box-items-point">
                <div class="box-items-point">
                    <img :src="`${$apiDevuelvoya}images/shared/encontrar.png`" alt="">
                    <span class="escritura">{{ $t('Diagnóstico operacional') }}</span>
                </div>
            </div>
            <div class="sub-box-items-point">
                <div class="box-items-point">
                    <img :src="`${$apiDevuelvoya}images/shared/medalla.png`" alt="">
                    <span class="escritura">{{ $t('Operaciones especiales por objetivos') }}</span>
                </div>
            </div>
            <div class="sub-box-items-point">
                <div class="box-items-point">
                    <img :src="`${$apiDevuelvoya}images/shared/cambio.png`" alt="">
                    <span class="escritura">{{ $t('Gestión de recambios') }}</span>
                </div>
            </div>
            <div class="sub-box-items-point">
                <div class="box-items-point">
                    <img :src="`${$apiDevuelvoya}images/shared/regreso.png`" alt="">
                    <span class="escritura">{{ $t('Gestión de devoluciones') }}</span>
                </div>
            </div>
            <div class="sub-box-items-point">
                <div class="box-items-point">
                    <img :src="`${$apiDevuelvoya}images/shared/correcto.png`" alt="">
                    <span class="escritura">{{ $t('Primera y segunda gestión') }}</span>
                </div>
            </div>
            <div class="sub-box-items-point">
                <div class="box-items-point">
                    <img :src="`${$apiDevuelvoya}images/shared/maquina-elevadora.png`" alt="">
                    <span class="escritura">{{ $t('Cierres físicos y administrativos de stocks en comodato') }}</span>
                </div>
            </div>
            <div class="sub-box-items-point">
                <div class="box-items-point">
                    <img :src="`${$apiDevuelvoya}images/shared/caja.png`" alt="">
                    <span class="escritura">{{ $t('Refurbishing , re-embalado y armado de kits') }}</span>
                </div>
            </div>
            <div class="sub-box-items-point">
                <div class="box-items-point">
                    <img :src="`${$apiDevuelvoya}images/shared/robots.png`" alt="">
                    <span class="escritura">{{ $t('Gestión de scrap y auto-partes') }}</span>
                </div>
            </div>
        </div>
    </div>
   </div>

   <dialog-devolver
   v-if="abrirModalDevolucion"
   @close="abrirModalDevolucion = false"
   />

    <!-- tarjetas de logistica -->
    <div class="container-point">
        <div class="sub-box-point" id="openModalPointUno">
            <div class="box-point">
                {{ $t('Logística inversa') }}
                <div class="img-point">
                    <img :src="`${$apiDevuelvoya}images/shared/truckder.png`" alt="">
                </div>
            </div>
        </div>
        <div class="sub-box-point" id="openModalPointDos">
            <div class="box-point">
                {{ $t('Logística directa') }}
                <div class="img-point">
                    <img :src="`${$apiDevuelvoya}images/shared/truckizq.png`" alt="">
                </div>
            </div>
        </div>
    </div>

    <div class="main-trailer">
        <!-- barra izquierda de servicios -->
        <div class="container-lista">
            <div class="sub-box-lista">
                <div class="box-lista">
                    <div class="titulo-lista">
                        <span class="taitel-lista">{{ $t('Última milla directa') }}
                            <img :src="`${$apiDevuelvoya}images/shared/trucklistazul.png`" alt="">
                        </span>
                    </div>
                    <div class="items-lista">
                        <img :src="`${$apiDevuelvoya}images/shared/inven.png`" alt="">
                        <span class="taitel-items">{{ $t('Gestión de proveedores') }}</span>
                    </div>
                    <div class="items-lista">
                        <img :src="`${$apiDevuelvoya}images/shared/inventario.png`" alt="">
                        <span class="taitel-items">{{ $t('Administración de Stocks') }}</span>
                    </div>
                    <div class="items-lista">
                        <img :src="`${$apiDevuelvoya}images/shared/fabrica.png`" alt="">
                        <span class="taitel-items">{{ $t('Gestión de Stocks Outlets Fulfillment') }}</span>
                    </div>
                    <div class="items-lista">
                        <img :src="`${$apiDevuelvoya}images/shared/kits.png`" alt="">
                        <span class="taitel-items">{{ $t('Armado de kits de entrega') }}</span>
                    </div>
                    <div class="items-lista">
                        <img :src="`${$apiDevuelvoya}images/shared/almacen.png`" alt="">
                        <span class="taitel-items">{{ $t('Unificación y packaging') }}</span>
                    </div>
                    <div class="items-lista">
                        <img :src="`${$apiDevuelvoya}images/shared/mercado.png`" alt="">
                        <span class="taitel-items">{{ $t('Gestión de Clientes y Puntos de Venta') }}</span>
                    </div>
                    <div class="items-lista">
                        <img :src="`${$apiDevuelvoya}images/shared/megafono.png`" alt="">
                        <span class="taitel-items">{{ $t('Material Publicitarios y POP') }}</span>
                    </div>
                    <div class="items-lista">
                        <img :src="`${$apiDevuelvoya}images/shared/entrega-a-domicilio.png`" alt="">
                        <span class="taitel-items">{{ $t('Gestión de entrega domiciliarias') }}</span>
                    </div>
                    <div class="items-lista">
                        <img :src="`${$apiDevuelvoya}images/shared/soporte-tecnico.png`" alt="">
                        <span class="taitel-items">{{ $t('Gestión de Customer Services') }}</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- opciones centrales -->
        <div class="central">
            <section class="titulo-main">
                <div>
                    <span style="color:#0093f5;font-weight: 750;">{{$t('ultima milla')}}</span><br>
                    {{$t('Logistica Integral')}},<br>{{$t('inversa')}} y {{$t('directa')}} <br>
                    ¡{{$t('cuando')}} <span style="color:#0093f5;">{{$t('quieras')}}</span>!
                </div>
            </section>
            <div class="container-box-boxes">
                <section class="boxes">
                  
                    <div @click="goPath('https://cliente.devuelvoya.com/autogestion')" class="box" id="gestion">
                        <div class="contenidodeicono">
                            <img class="imagenes-svg" :src="`${$apiDevuelvoya}images/shared/man.png`" alt="">
                        </div>
                        <h3 class="letraachetres" id="tengoEquipo"> <strong>{{ $t('Devolver equipo') }}</strong></h3>
                    </div>
                    <div @click="goRoute({name:'Distribucion'})" class="box" id="corptraslados">
                        <div class="contenidodeicono">
                            <img class="imagenes-svg" :src="`${$apiDevuelvoya}images/shared/bow2.png`" alt="">
                        </div>
                        <h3 class="letraachetres">{{ $t('Distribución') }}</h3>
                    </div>
                    <div @click="goRoute({name:'Contacto'})" class="box" id="ecommerce">
                        <div class="contenidodeicono">
                            <img class="imagenes-svg" :src="`${$apiDevuelvoya}images/shared/computer.png`" alt="">
                        </div>
                        <h3 class="letraachetres">{{ $t('Contacto') }}</h3>
                    </div>
                    <div @click="goRoute({name:'Logistica'})" class="box" id="corporativo">
                        <div class="contenidodeicono">
                            <img class="imagenes-svg" :src="`${$apiDevuelvoya}images/shared/car2.png`" alt="">
                        </div>
                        <h3 class="letraachetres">{{ $t('Logística inversa') }}</h3>
                    </div>
                    
                    <div @click="goPath('https://cliente.devuelvoya.com/autogestion/listado/cobertura')" class="box" id="red">
                        <div class="contenidodeicono">
                            <img class="imagenes-svg" :src="`${$apiDevuelvoya}images/shared/shop.png`" alt="">
                        </div>
                        <h3 class="letraachetres ">{{ $t('Red Express') }}</h3>
                    </div>
                    <div @click="register()" class="box openModalWorking">
                        <div class="contenidodeicono">
                            <img class="imagenes-svg" :src="`${$apiDevuelvoya}images/shared/team.png`" alt="">
                        </div>
                        <h3 class="letraachetres">{{ $t('Registrarse') }}</h3>
                    </div>
                    <div @click="getInto()" class="box" id="recolectores">
                        <div class="contenidodeicono">
                            <img class="imagenes-svg" :src="`${$apiDevuelvoya}images/shared/pos.png`" alt="">
                        </div>
                        <h3 class="letraachetres">{{ $t('Ingresar') }}</h3>
                    </div>
                </section>
            </div>
        </div>

            <!-- barra de servicios derecha -->
            <div class="container-lista">
                <div class="sub-box-lista">
                    <div class="box-lista">
                        <div class="titulo-lista">
                            <span class="taitel-lista dos">{{ $t('Última milla inversa') }}
                                <img :src="`${$apiDevuelvoya}images/shared/trucklist2azul.png`" alt="">
                            </span>
                        </div>
                        <div class="items-lista">
                            <span class="taitel-items">{{ $t('Geoposicionamiento') }} </span> <img :src="`${$apiDevuelvoya}images/shared/radar.png`" alt="">
                        </div>
                        <div class="items-lista">
                            <span class="taitel-items">{{ $t('Diagnóstico operacional') }} </span><img :src="`${$apiDevuelvoya}images/shared/encontrar.png`" alt="">
                        </div>

                        <div class="items-lista">
                            <span class="taitel-items">{{ $t('Operaciones especiales por objetivos') }} </span>
                            <img :src="`${$apiDevuelvoya}images/shared/medalla.png`" alt="">
                        </div>
                        <div class="items-lista">
                            <span class="taitel-items">{{ $t('Gestión de recambios') }}</span>
                            <img :src="`${$apiDevuelvoya}images/shared/cambio.png`" alt="">
                        </div>
                        <div class="items-lista">
                            <span class="taitel-items">{{ $t('Gestión de devoluciones') }}</span>
                            <img :src="`${$apiDevuelvoya}images/shared/regreso.png`" alt="">
                        </div>

                        <div class="items-lista">
                            <span class="taitel-items">{{ $t('Primera y segunda gestión') }}</span>
                            <img :src="`${$apiDevuelvoya}images/shared/correcto.png`" alt="">
                        </div>
                        <div class="items-lista">
                            <span class="taitel-items">{{ $t('Cierres físicos y administrativos de stocks en comodato') }}
                            </span>
                            <img :src="`${$apiDevuelvoya}images/shared/maquina-elevadora.png`" alt="">
                        </div>

                        <div class="items-lista">
                            <span class="taitel-items">{{ $t('Refurbishing , re-embalado y armado de kits') }}</span>
                            <img :src="`${$apiDevuelvoya}images/shared/caja.png`" alt="">
                        </div>
                        <div class="items-lista">
                            <span class="taitel-items">{{ $t('Gestión de scrap y auto-partes') }}</span>
                            <img :src="`${$apiDevuelvoya}images/shared/robots.png`" alt="">
                        </div>
                    </div>
                </div>
            </div>
    </div>
    <!-- preguntas frecuentes -->
    <div class="flex justify-center boton-l">
        <div class="bg-blue-400 p-2 rounded text-white" @click="goQuestion()" >{{ $t("Preguntas frecuentes") }}</div>
    </div>

    <!-- animacion auto -->
    <div class="car-wrapper">
        <div class="box-car-wrapper">
            <img class="car-moving" :src="`${$apiDevuelvoya}images/shared/car.png`" alt="">
            <div class="wind">
                <div class="p p1"></div>
                <div class="p p2"></div>
                <div class="p p3"></div>
                <div class="p p4"></div>
                <div class="p p5"></div>
            </div>
        </div>
    </div>

    <div class="container-artisan flex justify-center my-4">
        <div class="titulo-primary ">
            <span class="c-text-blue text-lg font-bold">Logística de ultima milla, directa e inversa para;</span>
        </div>
    </div>

    <div class="container-artisan">
        <aside>
            <div class="containermarve">
                <!-- slider -->
                <ul id="autoplay" class="ulmarvel" style="border-radius: 20px;">
                    <!-- --------- 1--------->
                    <li class="item-a">
                    <!-- slider box -->
                    <div class="boxmarve">
                        <img :src="`${$apiDevuelvoya}images/shared/mercado.png`" class="model">
                        <h5>{{ $t('Terminal de Pago (Pos)') }}</h5>
                    </div>
                    </li>
                    <!-- --------- 2--------->
                    <li class="item-a">
                        <!-- slider box -->
                        <div class="boxmarve">
                            <!-- <p class="marvel">Marvel</p> -->
                            <!-- model -->
                            <img :src="`${$apiDevuelvoya}images/shared/wifibox.png`" class="model">
                            <h5>{{ $t('Modems') }}</h5>

                        </div>
                    </li>
                    <!-- --------- 3--------->
                    <li class="item-a">
                        <!-- slider box -->
                        <div class="boxmarve">
                            <!-- <p class="marvel">Marvel</p> -->
                            <!-- model -->
                            <img :src="`${$apiDevuelvoya}images/shared/rollobox.png`" class="model">
                            <h5>{{ $t('Insumos') }}</h5>

                        </div>
                    </li>

                    <!-- --------- 5--------->
                    <li class="item-a">
                        <!-- slider box -->
                        <div class="boxmarve">
                            <!-- <p class="marvel">Marvel</p> -->
                            <!-- model -->
                            <img :src="`${$apiDevuelvoya}images/shared/satelite.png`" class="model">
                            <h5>{{$t('Antenas')}}</h5>
                        </div>
                    </li>
                    <!-- --------- 10--------->
                    <li class="item-a">
                        <!-- slider box -->
                        <div class="boxmarve">
                            <img :src="`${$apiDevuelvoya}images/shared/smartphone.png`" class="model">
                            <h5>{{$t('Celulares y Accesorios')}}</h5>
                        </div>
                    </li>
                    <!-- --------- 4--------->
                    <li class="item-a">
                        <!-- slider box -->
                        <div class="boxmarve">
                            <!-- <p class="marvel">Marvel</p> -->
                            <!-- model -->
                            <img :src="`${$apiDevuelvoya}images/shared/codificador.png`" class="model">
                            <h5>{{$t('Decodificadores / Canaleras')}}</h5>
                        </div>
                    </li>
                    <!-- --------- 7--------->
                    <li class="item-a">
                        <!-- slider box -->
                        <div class="boxmarve">
                            <img :src="`${$apiDevuelvoya}images/shared/printerbox.png`" class="model">
                            <h5>{{$t('Controladores Fiscales')}}</h5>
                        </div>
                    </li>

                    <!-- --------- 8--------->
                    <li class="item-a">
                        <!-- slider box -->
                        <div class="boxmarve">
                            <img :src="`${$apiDevuelvoya}images/shared/discobox.png`" class="model">
                            <h5>{{$t('Partes Hardware')}}</h5>
                        </div>
                    </li>
                    <!-- --------- 9--------->
                    <li class="item-a">
                        <!-- slider box -->
                        <div class="boxmarve">
                            <img :src="`${$apiDevuelvoya}images/shared/atm.png`" class="model">
                            <h5>{{$t('ATMs')}}</h5>
                        </div>
                    </li>
                    <!-- --------- 11--------->
                    <li class="item-a">
                        <!-- slider box -->
                        <div class="boxmarve">
                            <img :src="`${$apiDevuelvoya}images/shared/controlremoto.png`" class="model">
                            <h5>{{$t('Controles Remotos')}}</h5>
                        </div>
                    </li>
                    <!-- --------- 12--------->
                    <li class="item-a">
                        <!-- slider box -->
                        <div class="boxmarve">
                            <img :src="`${$apiDevuelvoya}images/shared/hdmi.png`" class="model">
                            <h5>{{$t('Accesorios')}}</h5>
                        </div>
                    </li>
                    <!-- --------- 13--------->
                    <li class="item-a">
                        <!-- slider box -->
                        <div class="boxmarve">
                            <img :src="`${$apiDevuelvoya}images/shared/centrales.png`" class="model">
                            <h5>{{$t('Centrales Telefonicas')}}</h5>
                        </div>
                    </li>
                </ul>
            </div>
        </aside>
    </div>

    <div class="container-artisan my-8">
        <div class="titulo-primary flex justify-center ">
            <span class="c-text-blue text-lg font-bold">¿Que beneficios tenés?</span>
        </div>
    </div>

    <div class="container-artisan director-contenedor-beneficios">

        <div class="contenedor-beneficio-total">
            <div class="box-beneficio-total">
                <img :src="`${$apiDevuelvoya}images/shared/presente23.png`" alt="" class="imagen-beneficio-total">
                <div class="contenedor-texto-beneficio">
                    <h4 class="titulo-beneficio-total">{{$t('Express sin limites')}} <span class="cambio-color-beneficio"><br>{{$t('Cobertura total')}}</span></h4>
                    <div class="parrafo-total">
                        <span class="titulo-parrafos uno-bene"><strong>{{$t('Recolectores')}}</strong> {{$t('en todo el pais')}}</span><br>
                        <span class="titulo-parrafos dos-bene"><strong>{{$t('Red de comercios')}}</strong> {{$t('Express para')}}</span><span class="titulo-parrafos tres-bene"> {{$t('entregas y retiros en')}} <strong>{{$t('todo el país')}}</strong></span><br>
                        <span class="titulo-parrafos cuatro-bene"><strong>{{$t('El cliente elige')}}</strong> {{$t('cuando, donde')}}</span><br>
                        <span class="titulo-parrafos cinco-bene">{{$t('y como lo recibe')}}</span>
                    </div>
                    <!-- <a href="<?= base_url ?>express/cobertura">
                        <div class="boton-beneficio">
                            Explorar
                        </div>
                    </a> -->
                </div>
            </div>
        </div>

        <div class="contenedor-mini-beneficios">
            <div class="box-mini-beneficios">
                <div class="imagen-mini-beneficios">
                    <font-awesome-icon class="img-bene"  icon="truck-pickup" />
                </div>

                <!-- <p>texto beneficio</p> -->
            </div>

            <div class="box-mini-beneficios">
                <div class="imagen-mini-beneficios">
                    <font-awesome-icon class="img-bene"  icon="tag" />
                </div>

                <!-- <p>texto beneficio</p> -->
            </div>
            <div class="box-mini-beneficios">
                <div class="imagen-mini-beneficios">
                    <font-awesome-icon class="img-bene"  icon="shopping-cart" />
                </div>

                <!-- <p>texto beneficio</p> -->
            </div>
            <div class="box-mini-beneficios">
                <div class="imagen-mini-beneficios">
                    <font-awesome-icon class="img-bene"  icon="location-arrow" />
                </div>

                <!-- <p>texto beneficio</p> -->
            </div>
            <div class="box-mini-beneficios">
                <div class="imagen-mini-beneficios">
                    <font-awesome-icon class="img-bene"  icon="money-bill" />
                </div>

                <!-- <p>texto beneficio</p> -->
            </div>
        </div>

        <div class="contenedor-beneficio-total-dos">
            <div class="box-beneficio-total">
                <img :src="`${$apiDevuelvoya}images/shared/waree.png`" alt="" class="imagen-beneficio-total">
                <div class="contenedor-texto-beneficio">
                    <h4 class="titulo-beneficio-total">{{$t('Asistencia y distribución')}} <span class="cambio-color-beneficio"><br>{{$t('Full stock')}}</span></h4>
                    <div class="parrafo-total">
                        <span class="titulo-parrafos uno-bene"><strong>{{$t('Logistica inversa y directa')}}</strong> {{$t('de equipamientos de valor')}}</span><br>
                        <span class="titulo-parrafos uno-bene"><strong>{{$t('Reposición')}}</strong> {{$t('de stock en tiempo real')}}</span><br>
                        <span class="titulo-parrafos tres-bene"><strong>{{$t('Administración')}}</strong> {{$t('y gestión en planta')}}</span>
                    </div>
                    <!-- <a href="<?= base_url ?>express/corporativo">
                        <div class="boton-beneficio">
                            Empezar
                        </div>
                    </a> -->
                </div>
            </div>
        </div>
        <div class="contenedor-item-texto">
            <div class="sub-contenedor-item-texto">
                <div class="box-item-texto">
                    <font-awesome-icon icon="gauge" />
                </div>
                <div class="box-titulo-parrafo">
                    <h3 class="texto-item">{{$t('Ahorra tiempo')}}</h3>
                    <p class="parrafo-en-texto">{{$t('Express se encarga de todo.')}}</p>
                </div>
            </div>
            <div class="sub-contenedor-item-texto">
                <div class="box-item-texto">
                    <font-awesome-icon icon="circle-check" />
                </div>
                <div class="box-titulo-parrafo">
                    <h3 class="texto-item">{{$t('Eficiencia')}}</h3>
                    <p class="parrafo-en-texto">{{$t('Obtenes los mejores resultados')}}</p>
                </div>
            </div>
            <div class="sub-contenedor-item-texto">
                <div class="box-item-texto">
                    <font-awesome-icon icon="building" />
                </div>
                <div class="box-titulo-parrafo">
                    <h3 class="texto-item">{{$t('Empresa top')}}</h3>
                    <p class="parrafo-en-texto">{{$t('Lleva tu empresa a la cima.')}}</p>
                </div>
            </div>
        </div>
        <div class="contenedor-beneficio-total-tres">
            <div class="box-beneficio-total">
                <img :src="`${$apiDevuelvoya}images/shared/routt.png`" alt="" class="imagen-beneficio-total">
                <div class="contenedor-texto-beneficio">
                    <h4 class="titulo-beneficio-total">{{$t('Costo beneficio')}}<span class="cambio-color-beneficio"><br>{{$t('Precio competitivo')}}
                        </span>
                    </h4>
                    <div class="parrafo-total">
                        <span class="titulo-parrafos uno-bene"><strong>{{$t('Mejor precio')}}</strong> {{$t('del mercado.')}}</span><br>
                        <span class="titulo-parrafos uno-bene"><strong>{{$t('Administración y gestión')}}</strong>
                            {{$t('total')}}</span><br><span class="titulo-parrafos tres-bene"><strong>{{$t('Sistemas automatizados')}}</strong> {{$t('para mayor eficiencia')}}
                        </span>
                    </div>
                    <!-- <a href="<?= base_url ?>express/traslados">
                        <div class="boton-beneficio">
                            Empezar
                        </div>
                    </a> -->
                </div>
            </div>
        </div>
    </div>

    <div class="contenedor-tira-final">
        <div class="tira-final-texto">
            <h3 class="titulo-tira-final">{{$t('Somos tu mejor aliado, empeza a disfrutar de nuestros servicios')}}</h3>
            <!-- <p class="parrafo-tira-final">Empeza a disfrutar de nuestro </p> -->

            <a >
                <div @click="goRoute({name:'Contacto'})" class="boton-tira-final">
                    {{$t('Disfrutar')}}
                </div>
            </a>
        </div>

        <div class="sub-box-tira-final">
            <img class="imagen-tira-final" :src="`${$apiDevuelvoya}images/shared/avatar.png`" alt="">
        </div>

    </div>

    <div class="container-artisan" >
        <div class="accordion">
          <div class="accordion-item">
              <div class="accordion-item-header" id="acordion-ancla">
                  {{$t('¿Qué es un comercio Express y qué ventajas tiene?')}}
              </div>
              <div class="accordion-item-body">
                  <div class="accordion-item-body-content">
                      {{$t('Un comercio express es una sucursal adherida a Express metropolitana, cumple la función de recepcionar equipos previamente pactados. Esta opción es la mejor para generar ingresos extras en tu comercio.')}}
                  </div>
              </div>
          </div>
          <div class="accordion-item">
              <div class="accordion-item-header">
                  {{$t('¿Qué es un Recolector / Repartidor?')}}
              </div>
              <div class="accordion-item-body">
                  <div class="accordion-item-body-content">
                      <ul>
                          <li>
                              {{$t('Recolector: Se encarga de visitar los domicilios donde están ubicados los equipos a recuperar, para su búsqueda y rendición.')}}
                          </li>
                          <li>
                              {{$t('Repartidor: Se encarga de buscar equipos, repuestos, documentos y objetos que se necesiten para trasladar desde un punto A determinado a otro punto B, es un tiempo estimado.')}}
                          </li>
                      </ul>
                  </div>
              </div>
          </div>
          <div class="accordion-item">
              <div class="accordion-item-header">
                  {{$t('¿Por qué son tan amplios los horarios de recuperación de Express?')}}
              </div>
              <div class="accordion-item-body">
                  <div class="accordion-item-body-content">
                      <ul style="padding-left: 1rem;">
                          {{$t('Porque el sistema Express piensa en su comodidad con un sistema 365 las 24hs. La distribución es de lunes a viernes de 08 a 18 hs, Sabados, domingos y feriados. Sin embargo podra pactar horarios mas amplios hasta 24 hs por medio de nuestra red de comercios.')}}
                      </ul>
                  </div>
              </div>
          </div>
          <div class="accordion-item">
              <div class="accordion-item-header">
                  {{$t('¿Por qué debo devolver el equipo solicitado?')}}
              </div>
              <div class="accordion-item-body">
                  <div class="accordion-item-body-content">
                      <ul style="padding-left: 1rem;">
                          {{$t('Porque fue adquirido en comodato, por lo tanto es propiedad de la compañía. Si no lo devuelvo, seguiré recibiendo llamadas para la devolución, ya que constituye una deuda.')}}
                      </ul>
                  </div>
              </div>

          </div>
          <div class="accordion-item">
              <div class="accordion-item-header">
                  {{$t('¿Que pasa si ya devolvi el equipo reclamado?')}}
              </div>
              <div class="accordion-item-body">
                  <div class="accordion-item-body-content">
                      <ul style="padding-left: 1rem;">
                          {{$t('Póngase en contacto con atención al cliente con el operador reclamante.')}}
                      </ul>
                  </div>
              </div>

          </div>
          <div class="accordion-item">
              <div class="accordion-item-header">
                  {{$t('¿Qué posibilidades de devolución o entrega tengo?')}}
              </div>
              <div class="accordion-item-body">
                  <div class="accordion-item-body-content">
                      <ul style="padding-left: 1rem;">
                          <ul>
                              <li>{{$t('Devolución gratuita por correo')}}</li>
                              <li>{{$t('Devolución gratuita por la red de comercios express')}}</li>
                              <li>{{$t('Devolución gratuita por los sistemas de transportes y terminales de ómnibus')}}</li>
                              <li>{{$t('Coordinando una visita domiciliaria')}}</li>
                          </ul>
                      </ul>
                  </div>
              </div>

          </div>
          <div class="accordion-item">
              <div class="accordion-item-header">
                  {{$t('¿Qué documentación recibo al momento de la entrega?')}}
              </div>
              <div class="accordion-item-body">
                  <div class="accordion-item-body-content">
                      <ul style="padding-left: 1rem;">
                          {{$t('Recibirá un comprobante oficial, pre-impreso con los datos del equipo y de quien lo devuelve, firmado por un representante de Express o recibirá un comprobante electrónico equivalente.')}}
                      </ul>
                  </div>
              </div>

          </div>
          <div class="accordion-item">
              <div class="accordion-item-header">
                  {{$t('¿Por qué Express es una empresa Oficial de recupero?')}}
              </div>
              <div class="accordion-item-body">
                  <div class="accordion-item-body-content">
                      {{$t('Porque es una empresa ofialmente registrada en el ENTE NACIONAL DE COMUNICACION (ENACOM), bajo el registro postal Nro 968. Porque tiene vinculacion contractual con las empresas requirientes.')}}
                  </div>
              </div>

          </div>
        </div>
    </div>
  </div>
  <Footer/>
</template>

<script>
// @ is an alias to /src
import '@/assets/js/Modal_main';
import Footer from '@/components/Footer.vue';
import DialogDevolver from '@/components/DialogDevolver.vue'

export default {
  components: {
    Footer,
    DialogDevolver
  },
  data() {
    return {
        abrirModalDevolucion : true
    }
  },
  mounted() {
    const accordionItemHeaders = document.querySelectorAll('.accordion-item-header');
    if (accordionItemHeaders) {
      accordionItemHeaders.forEach((accordionItemHeader) => {
        accordionItemHeader.addEventListener('click', () => {
          accordionItemHeader.classList.toggle('active');
          const accordionItemBody = accordionItemHeader.nextElementSibling;
          if (accordionItemHeader.classList.contains('active')) {
            accordionItemBody.style.maxHeight = `${accordionItemBody.scrollHeight}px`;
          } else {
            accordionItemBody.style.maxHeight = 0;
          }
        });
      });
    }
  },
  methods: {
    goQuestion() {
      const element = document.getElementById('acordion-ancla');
      element.scrollIntoView();
    },
    getInto() {
      document.location.href = process.env.VUE_APP_OPERACIONES;
    },
    register() {
      
      document.location.href = `${process.env.VUE_APP_OPERACIONES}autenticacion/registro/iniciar`;
    },
    goRoute(path) {
      this.$router.push(path);
    },
    goPath(path){
        window.location.href = path
    }
  },
};
</script>

<style scoped>
@import '../assets/css/home.css';
@import '../assets/css/lightslider.css';
</style>
